import React from "react"
import { Parallax } from "react-scroll-parallax"

import Medium from "../components/medium"

import thirdImage from "../assets/images/news/3.jpg"
import sixthImage from "../assets/images/news/6.jpg"
import eigthImage from "../assets/images/news/8.jpg"

class News extends React.PureComponent {
  render() {
    const { isMobile, y } = this.props
    return isMobile ? (
      <div className="w-screen ml-8 mt-24 md:mt-24" id="news">
        <h2 className="font-headline text-5xl md:text-5xl md:mt-24 font-black leading-tight pb-12">
        Publications.
        </h2>
        <div className="relative mr-8">
          <div className="w-4/5 ">
            <Parallax y={[-20, 20]}>
              <img className="w-64 h-64 bg-cover" src={thirdImage} />
            </Parallax>
          </div>

          <a
            className="w-4/5 border-gray-300 bg-white border p-8 mt-12 absolute"
            style={{ top: 0, left: 40 }}
            target="_blank"
            href="https://www.sbs.ox.ac.uk/sites/default/files/2020-01/tokenisation.pdf"
            id="interviewDownloadCta"
          >
            <div>
              <span className="font-bold text-2xl">
                Oxford Research
              </span>
            </div>
            <div className="mt-4">
              <p className="font-body text-sm font-bold uppercase tracking-wider">
                Tokenization – Future of Real Estate Investment?
              </p>
            </div>
            <div className="mt-4">
              <p className="font-body text-base">
                A comprehensive view on real estate investment products compared to digital securities, like the BrickMark Token
              </p>
            </div>
            {/*<div className="pt-4">
              <p className="font-body text-base">
                Ganz neue Qualität von Investments mithilfe von Token und
                Blockchain.
              </p>
    </div> */}
            <div className="pt-4">
              <div className="text-brickmark-orange text-base">Read more</div>
            </div>
          </a>
        </div>

        <div className="relative mt-20 md:mt-20 mr-8">
          <div className="w-4/5 ">
            <Parallax y={[-20, 20]}>
              <img className="w-64 h-64 bg-cover" src={sixthImage} />
            </Parallax>
          </div>

          <a
            className="w-4/5 border-gray-300 bg-white border p-8 mt-12 absolute"
            style={{ top: 0, left: 40 }}
            href="https://medium.com/@BrickmarkA/stos-backed-by-real-estate-challenges-and-opportunities-part-1-ba96d913a46c"
            target="_blank"
          >
            <div>
              <span className="font-bold text-2xl">
                Insight
              </span>
            </div>
            <div className="mt-4">
              <p className="font-body text-sm font-bold uppercase tracking-wider">
                STOs backed by Real Estate
              </p>
            </div>
            <div className="pt-4">
              <p className="font-body text-base">
                The Advisory Board consists of the most including real estate,
                finance, and legal and commynity & technology.
              </p>
            </div>
            <div className="pt-4">
              <div className="text-brickmark-orange text-base">Read more</div>
            </div>
          </a>
        </div>

        <div className="relative mt-32 md:mt-20 mr-8">
          <div className="w-4/5 mt-12">
            <Parallax y={[-20, 20]}>
              <img className="w-64 h-64 bg-cover" src={eigthImage} />
            </Parallax>
          </div>
          <a
            className="w-4/5 border-gray-300 bg-white border p-8 mt-12 absolute"
            style={{ top: 0, left: 40 }}
            href="https://medium.com/cv-vc/top-50-update-new-startups-entering-the-playing-field-9960b0a3e150"
            target="_blank"
          >
            <div>
              <span className="font-bold text-2xl">
                Interview
              </span>
            </div>
            <div className="mt-4">
              <p className="font-body text-sm font-bold uppercase tracking-wider">
                Top 50 Update: New Startups Entering...
              </p>
            </div>
            <div className="pt-4">
              <p className="font-body text-base">
                Every quarter investment company CV VC, in collaboration with
                PwC Strategy & and IT partner...
              </p>
            </div>
            <div className="pt-4">
              <div className="text-base text-brickmark-orange">Read more</div>
            </div>
          </a>
        </div>
      </div>
    ) : (
      <div className="tile self-center mr-80 mt-8">
        <h2 className="font-headline text-6xl ml-24 md:text-5xl font-black leading-tight">
        Publications.
        </h2>
        <div className="flex flex-row pl-32">
          <div className="relative self-center w-4/12 mt-40">
            <div className="w-4/5 ">
              <img className="w-64 h-64 bg-cover" src={thirdImage} />
            </div>

            <a
              className="w-4/5 border-gray-300 bg-white border p-8 mt-12 absolute"
              id="interviewDownloadCta"
              style={{ top: 0, left: 40 }}
              href="https://www.sbs.ox.ac.uk/sites/default/files/2020-01/tokenisation.pdf"
              target="_blank"
            >
              <div>
                <span className="font-bold text-2xl">
                  Oxford Research
                </span>
              </div>
              <div className="mt-4">
                <p className="font-body text-sm font-bold">
                  Tokenization – Future of Real Estate Investment?
                </p>
              </div>
              <div className="mt-4">
                <p className="font-body text-sm">
                  A comprehensive view on real estate investment products compared to digital securities, like the BrickMark Token
                </p>
              </div>
              {/* <div className="pt-4">
                <p className="font-body text-sm">
                  Ganz neue Qualität von Investments mithilfe von Token und
                  Blockchain
                </p>
                </div> */}
              <div className="pt-4">
                <div className="text-sm text-brickmark-orange">Read more</div>
              </div>
            </a>
          </div>
          <div className="relative self-center w-4/12">
            <div className="w-4/5 ">
              <img className="w-64 h-64 bg-cover" src={sixthImage} />
            </div>

            <a
              className="w-4/5 border-gray-300 bg-white border p-8 mt-12 absolute"
              style={{ top: 0, left: 40 }}
              href="https://medium.com/@BrickmarkA/stos-backed-by-real-estate-challenges-and-opportunities-part-1-ba96d913a46c"
              target="_blank"
            >
              <div>
                <span className="font-bold text-2xl">
                  Insights
                </span>
              </div>
              <div className="mt-4">
                <p className="font-body text-sm font-bold">
                  STOs backed by Real Estate
                </p>
              </div>
              <div className="pt-4">
                <p className="font-body text-sm">
                  The Advisory Board consists of the most including real estate,
                  finance, and legal and commynity & technology.
                </p>
              </div>
              <div className="pt-4">
                <div className="text-sm text-brickmark-orange">Read more</div>
              </div>
            </a>
          </div>

          <div className="relative self-center w-4/12 mt-40 z-20">
            <div className="w-4/5">
              <img className="w-64 h-64 bg-cover" src={eigthImage} />
            </div>
            <a
              className="w-4/5 border-gray-300 bg-white border p-8 mt-12 absolute"
              style={{ top: 0, left: 40 }}
              href="https://medium.com/cv-vc/top-50-update-new-startups-entering-the-playing-field-9960b0a3e150"
              target="_blank"
            >
              <div>
                <span className="font-bold text-2xl">
                  Insights
                </span>
              </div>
              <div className="mt-4">
                <p className="font-body text-sm font-bold">
                  Top 50 Update: New Startups Entering...
                </p>
              </div>
              <div className="pt-4">
                <p className="font-body text-sm">
                  Every quarter investment company CV VC, in collaboration with
                  PwC Strategy & and IT partner...
                </p>
              </div>
              <div className="pt-4">
                <div className="text-sm text-brickmark-orange">Read more</div>
              </div>
            </a>
          </div>
        </div>
      </div>
    )
  }
}

export default News
