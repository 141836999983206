import React from "react"
import BodyClassName from "react-body-classname"
import ScrollHorizontal from "./horizontal"
import withWindowDimensions from "./viewport"

import Menu from "../components/menu"

class ScrollWrapper extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      offset: 0,
      scrollToValue: 0,
    }
  }

  render() {
    const { isMobile, children, width, height } = this.props

    return isMobile ? (
      <div>
        <Menu
          {...this.props}
          {...this.state}
          scrollToValue={scrollToValue => this.setState({ scrollToValue })}
        />
        {children}      
      </div>
    ) : (
      <BodyClassName className="desktop">
        <div style={{ width, height }}>          
          <Menu
            {...this.props}
            {...this.state}
            scrollToValue={scrollToValue => this.setState({ scrollToValue })}
          />
          <ScrollHorizontal
            pageLock
            reverseScroll
            onScroll={offset => this.setState({ offset })}
            scrollToValue={this.state.scrollToValue}
          >
            {React.Children.map(children, child =>
              React.cloneElement(child, { ...this.state })
            )}
          </ScrollHorizontal>
        </div>
      </BodyClassName>
    )
  }
}

export default withWindowDimensions(ScrollWrapper)
