import React from "react"
import { FaLinkedinIn, FaFacebookF, FaTwitter, FaMedium } from "react-icons/fa"
import MailchimpForm from "../components/MailchimpForm"

class Mission extends React.PureComponent {
  render() {
    const { isMobile, y } = this.props
    return isMobile ? (
      <div className="w-full mt-48 md:mt-30 pt-8 relative" id="footer">
        <div
          className="bg-brickmark-blue absolute w-screen"
          style={{ transform: "skewY(-7deg)", top: "-5vh", height: 200 }}
        />
        <div className="bg-brickmark-blue pb-12">
          <h2 className="font-headline text-4xl md:text-5xl font-black leading-tight text-white opacity-90  mt-8 ml-8">
            Keep Updated.
          </h2>
          <div className="flex flex-row justify-center mt-12 opacity-90 w-full">
            <div className="flex flex-row justify-between w-64">
              <a
                href="https://www.linkedin.com/company/brickmark-ag/"
                target="_blank"
                className="rounded-full h-12 w-12 flex items-center justify-center bg-brickmark-orange"
              >
                <div className="text-white">
                  <FaLinkedinIn />
                </div>
              </a>
              <a
                href="https://www.facebook.com/BrickMarkAG/"
                target="_blank"
                className="rounded-full h-12 w-12 flex items-center justify-center bg-brickmark-orange"
              >
                <div className="h-4 w-4 text-white">
                  <FaFacebookF />
                </div>
              </a>
              <a
                href="https://twitter.com/BrickmarkAG"
                target="_blank"
                className="rounded-full h-12 w-12 flex items-center justify-center bg-brickmark-orange"
              >
                <div className="h-4 w-4 text-white">
                  <FaTwitter />
                </div>
              </a>
              <a
                  href="https://medium.com/Brickmark-Ag"
                  target="_blank"
                  className="rounded-full h-12 w-12 flex items-center justify-center bg-brickmark-orange"
                >
                  <div className="h-4 w-4 text-white">
                    <FaMedium />
                  </div>
                </a>
            </div>
          </div>
          <div className="pl-8 pr-8">
            <MailchimpForm />
          </div>
          <div className="m-auto w-auto flex flex-col mt-8 justify-center">
            <div className="text-center">
              <a
                href="tel:+41415000140"
                className="text-base text-white opacity-90 cursor-pointer"
              >
                <span className="opacity-50">phone: </span>+41 41 500 01 40
              </a>
            </div>
            <div className="text-center mt-2">
              <a
                href="fax:+41415000141"
                className="text-base text-white opacity-90 cursor-pointer"
              >
                <span className="opacity-50">fax: </span>+41 41 500 01 41
              </a>
            </div>
            <div className="text-center mt-2">
              <a
                href="mailto:info@brickmark.net"
                className="text-base text-white opacity-90 cursor-pointer"
              >
                <span className="opacity-50">Email: </span>
                info@brickmark.net
              </a>
            </div>
          </div>
          <div className="m-auto w-auto flex flex-col mt-8 justify-center">
            <div className="text-center">
              <a
                target="_blank"
                href="docs/terms.pdf"
                className="text-sm text-white opacity-90 cursor-pointer text-base"
              >
                Terms of Use
              </a>
            </div>
            <div className="text-center mt-2">
              <a
                target="_blank"
                href="docs/privacy.pdf"
                className="cursor-pointer text-sm text-white opacity-90 text-base"
              >
                Privacy Notice
              </a>
            </div>
            <div className="text-center mt-2">
              <a
                target="_blank"
                href="docs/legal.pdf"
                className="cursor-pointer text-sm text-white opacity-90 text-base"
              >
                Imprint
              </a>
            </div>
          </div>
          <div className="mt-8 text-center font-body text-xs text-white opacity-50">
            <p className="">
              BrickMark AG. <br /> Seestrasse 5, CH - 6300 Zug, Switzerland.
            </p>
            <p>© 2019 BrickMark AG. All Rights Reserved.</p>
          </div>
        </div>
      </div>
    ) : (
      <div
        className="flex tile-half bg-brickmark-blue relative h-screen"
        style={{ minWidth: 900 }}
      >
        <div
          className="absolute bg-brickmark-blue tile-half h-screen z-0"
          style={{
            left: -290,
            width: 800,
            transform:
              "matrix3d(1, 0, 0, 0, -0.57735, 1, 0, 0, 0, 0, 1, 0, -81.1415, 0, 0, 1)",
          }}
        />
        <div className="flex flex-col z-10 overflow-hidden self-center">
          <h2 className="font-headline z-10 text-4xl md:text-5xl font-black leading-tight text-white opacity-90  mt-32 ml-24">
            Keep Updated.
          </h2>
          <div className="flex flex-row z-10 ml-32 mt-12">
            <div className="self-center w-3/6 mr-12">
              <div className="flex flex-row w-64 justify-between">
                <a
                  href="https://www.linkedin.com/company/brickmark-ag/"
                  target="_blank"
                  className="rounded-full h-12 w-12 flex items-center justify-center bg-brickmark-orange"
                >
                  <div className="text-white">
                    <FaLinkedinIn />
                  </div>
                </a>
                <a
                  href="https://www.facebook.com/BrickMarkAG/"
                  target="_blank"
                  className="rounded-full h-12 w-12 flex items-center justify-center bg-brickmark-orange"
                >
                  <div className="h-4 w-4 text-white">
                    <FaFacebookF />
                  </div>
                </a>
                <a
                  href="https://twitter.com/BrickmarkAG"
                  target="_blank"
                  className="rounded-full h-12 w-12 flex items-center justify-center bg-brickmark-orange"
                >
                  <div className="h-4 w-4 text-white">
                    <FaTwitter />
                  </div>
                </a>
                <a
                  href="https://medium.com/Brickmark-Ag"
                  target="_blank"
                  className="rounded-full h-12 w-12 flex items-center justify-center bg-brickmark-orange"
                >
                  <div className="h-4 w-4 text-white">
                    <FaMedium />
                  </div>
                </a>
              </div>
              <div className="">
                <MailchimpForm />
              </div>
            </div>

            <div className="self-center z-10 ml-8">
              <div className="flex flex-row justify-center opacity-90 w-full">
                <div className="m-auto w-auto flex flex-col justify-center">
                  <div className="font-body text-xs text-white opacity-50">
                    <p className="">
                      BrickMark AG. <br /> Seestrasse 5, CH - 6300 Zug, Switzerland.
                    </p>
                    <p>© 2019 BrickMark AG. All Rights Reserved.</p>
                  </div>
                  <div className="text-left mt-12">
                    <a
                      href="tel:+41415000140"
                      className="text-sm text-white opacity-90 cursor-pointer"
                    >
                      <span className="opacity-50">phone: </span>+41 41 500 01
                      40
                    </a>
                  </div>
                  <div className="text-left">
                    <a
                      href="fax:+41415000141"
                      className="text-sm text-white opacity-90 cursor-pointer"
                    >
                      <span className="opacity-50">fax: </span>+41 41 500 01 41
                    </a>
                  </div>
                  <div className="text-left">
                    <a
                      href="mailto:info@brickmark.net"
                      className="text-sm text-white opacity-90 cursor-pointer"
                    >
                      <span className="opacity-50">Email: </span>
                      info@brickmark.net
                    </a>
                  </div>

                  <div className="text-left mt-12">
                    <a
                      target="_blank"
                      href="docs/terms.pdf"
                      className="text-sm text-white opacity-90 cursor-pointer"
                    >
                      Terms of Use
                    </a>
                  </div>
                  <div className="text-left">
                    <a
                      target="_blank"
                      href="docs/privacy.pdf"
                      className="cursor-pointer text-sm text-white opacity-90"
                    >
                      Privacy Notice
                    </a>
                  </div>
                  <div className="text-left">
                    <a
                      target="_blank"
                      href="docs/legal.pdf"
                      className="cursor-pointer text-sm text-white opacity-90"
                    >
                      Imprint
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Mission
