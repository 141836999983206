import React from "react"
import { Parallax } from "react-scroll-parallax"

import firstImage from "../assets/images/1.jpg"
import secondImage from "../assets/images/2.jpg"
import sevenImage from "../assets/images/7.jpg"

class WhatWeDo extends React.PureComponent {
  render() {
    const { isMobile, y, offset } = this.props
    return isMobile ? (
      <div className="container w-screen pt-8 pb-8 mt-40 md:mt-64 relative" id="whatwedo">
        <div className="w-screen">
          <div className="pl-8 pr-8 pt-8">
            <h2
              className="font-headline text-4xl md:text-5xl md:w-4/5 font-black leading-tight"
              style={{
                background: `url(${sevenImage}) center`,
                backgroundPosition: `0px -${400 + y}px`,
                backgroundClip: "text",
                WebkitBackgroundClip: "text",
                color: "transparent",
              }}
            >
              Real Estate Investment & Management.
            </h2>
            <p className="mt-8 font-body font-light text-lg md:w-4/5">
              A team of leading industry professionals is assembling a real
              estate portfolio focused on value enhancing prime commercial
              property assets in Europe.
            </p>
          </div>
          <div className="mt-12 pl-8 pr-8 md:w-4/5 relative">
            <div className="w-2/5 ">
              <img src={firstImage} />
            </div>
            <div className="absolute w-3/4" style={{ top: 140, right: 60 }}>
              <Parallax y={[-20, 20]}>
                <img className="bg-cover" src={secondImage} />
              </Parallax>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div className="tile" style={{ minWidth: 1700 }}>
        <div className="flex flex-row h-screen">
          <h2
            className="mt-48 md:ml-32 self-center font-headline font-black pb-4"
            style={{
              background: `url(${sevenImage}) center`,
              backgroundPosition: `-${200 + offset / 10}px 0px`,
              backgroundSize: "cover",
              backgroundClip: "text",
              WebkitBackgroundClip: "text",
              color: "transparent",
              fontSize: 88,
              lineHeight: "94px",
            }}
          >
            Real Estate
            <br />
            <nobr>Investment &</nobr>
            <br />
            Management.
          </h2>

          <div className="self-center ml-32 w-2/12 -mt-64 relative">
            <p className="font-body font-light text-lg">
              A team of leading industry professionals is assembling a real
              estate portfolio focused on value enhancing prime commercial
              property assets in Europe.
            </p>
            <div
              className="absolute z-20"
              style={{
                top: 300,
                right: -300,
                width: 350,
                transform: `translateX(${offset / 20}px)`,
              }}
            >
              <img className="bg-cover" src={secondImage} />
            </div>
            <div
              className="absolute "
              style={{ top: 0, right: -350, width: 250 }}
            >
              <img src={firstImage} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default WhatWeDo
