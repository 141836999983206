import React from "react"

import { Link } from "gatsby"

import CookieConsent from "react-cookie-consent"
import { push as MobileMenu } from "react-burger-menu"

import ScrollWrapper from "../utils/scrollwrapper"
import withWindowDimensions from "../utils/viewport"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Logo from "../components/logo"

import { FaLinkedinIn, FaFacebookF, FaTwitter, FaMedium } from "react-icons/fa"

import Mission from "../sections/Mission"
import WhatWeDo from "../sections/WhatWeDo"
import Portfolio from "../sections/Portfolio"
import Team from "../sections/Team"
import News from "../sections/News"
import Presse from "../sections/Presse"
import Footer from "../sections/Footer"

import withRedirect from "../utils/redirect";

class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      y: 0,
      x: 0,
    }

    this.myRef = React.createRef()
  }

  componentDidMount() {
    // window.addEventListener("scroll", this.listenToScroll)
  }

  componentWillUnmount() {
    // window.removeEventListener("scroll", this.listenToScroll)
  }

  listenToScroll = e => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop

    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight

    const scrolledY = (winScroll / height) * 1000

    this.setState({
      y: Math.floor(scrolledY),
    })
  }

  render() {
    const { isMobile, height } = this.props
    return (
      <Layout>
        <SEO title="Brickmark — Home" />
        <div
          className="flex flex-col flex-1 overflow-hidden"
          id="container"
          style={{
            height: isMobile ? null : "100vh",
          }}
        >
          <div
            className="flex flex-row justify-between z-50"
            style={{
              position: isMobile ? "inherit" : "fixed",
              width: isMobile ? null : "100vw",
            }}
          >
            <div className="h-24 w-24 mt-8 ml-8">
              <Link to="/">
                <Logo />
              </Link>
            </div>
            <div className="flex flex-row justify-between mt-8 mr-6 ml-2">
              <div className=" cursor-pointer rounded-full bg-white w-12 h-12 flex items-center justify-center border hover:border-brickmark-orange mr-2">
                <a
                  href="https://www.linkedin.com/company/brickmark-ag/"
                  target="_blank"
                >
                  <FaLinkedinIn />
                </a>
              </div>
              <div className="cursor-pointer rounded-full bg-white w-12 h-12 flex items-center justify-center border hover:border-brickmark-orange mr-2">
                <a href="https://www.facebook.com/BrickMarkAG/" target="_blank">
                  <FaFacebookF />
                </a>
              </div>
              <div className="cursor-pointer rounded-full bg-white w-12 h-12 flex items-center justify-center border hover:border-brickmark-orange mr-2">
                <a
                  className="h-4 w-4"
                  href="https://twitter.com/BrickmarkAG"
                  target="_blank"
                >
                  <FaTwitter />
                </a>
              </div>
              <div className="cursor-pointer rounded-full bg-white w-12 h-12 flex items-center justify-center border hover:border-brickmark-orange">
                <a
                  className="h-4 w-4"
                  href="https://medium.com/Brickmark-Ag"
                  target="_blank"
                >
                  <FaMedium />
                </a>
              </div>
            </div>
          </div>
          <ScrollWrapper>
            <Mission {...this.props} {...this.state} />
            <WhatWeDo {...this.props} {...this.state} />
            <Portfolio {...this.props} {...this.state} />
            <Team {...this.props} {...this.state} ref={this.myRef} />
            <News {...this.props} {...this.state} />
            <Presse {...this.props} {...this.state} />
            <Footer {...this.props} {...this.state} />
          </ScrollWrapper>
          <CookieConsent
            buttonText="Got It!"
            location="top"
            containerClasses="bg-brickmark-blue font-body font-light text-lg"
            buttonClasses="bg-transparent font-body font-normal text-white border-solid border-2 pl-2 pr-2 border-white"
            buttonStyle={{
              paddingTop: "6px",
              paddingLeft: "10px",
              marginRight: "20px",
            }}
          >
            This website uses cookies to ensure you get the best experience on
            our website.
          </CookieConsent>
        </div>
      </Layout>
    )
  }
}

export default withRedirect(withWindowDimensions(IndexPage))
