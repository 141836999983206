import React from "react"
import { Parallax } from "react-scroll-parallax"
import maskLogo from "../assets/images/mask.jpg"
import { Link } from "gatsby"
import Modal from "react-modal"
import MailchimpForm from "../components/MailchimpForm";
import { IoIosClose } from "react-icons/io"
import Logo from "../components/logo"

Modal.setAppElement("#___gatsby")

class Mission extends React.PureComponent {
  state = {
    isModalOpen: false
  }

  openModal = () => this.setState({isModalOpen: true})

  closeModal = () => this.setState({isModalOpen: false})

  render() {
    const { isMobile, y, offset } = this.props

    return isMobile ? (
      <div className="flex flex-col flex-1 p-8 mt-10 md:mt-24 relative" id="mission">
        <div
          className="mt-12 w-full absolute z-0 "
          style={{
            top: 60,
            opacity: y < 80 ? `0.${y + 20}` : 1,
          }}
        >
          <Parallax y={[40, -40]}>
            <img
              className="w-10/12 md:w-full center md:pr-24 md:pl-32"
              src={maskLogo}
            />
          </Parallax>
        </div>
        <h1 className="flex-row font-headline md:text-5xl md:w-4/5 text-4xl font-black leading-tight z-10">
          Next Level <nobr>Real Estate</nobr> Investments
        </h1>
        <p className="flex-row mt-8 md:w-3/5 font-body font-light text-lg z-10">
          Providing universal access to the world’s premier real estate in a
          secure and efficient way.
        </p>
        <div class="mt-8 z-10">
          <Link to="/portfolio">
            <span className="font-body text-brickmark-orange font-medium text-xl border-solid border-brickmark-orange border rounded-lg p-3 bg-transparent">
              See Our Portfolio
            </span>
          </Link>
          <div className="mt-4">              
            <span class="ml-1 font-body text-brickmark-orange text-sm cursor-pointer" onClick={() => this.setState({isModalOpen: true})}>
              Or subscribe to our newsletter
            </span>                
          </div>
        </div>
        <Modal
            isOpen={this.state.isModalOpen}
            onRequestClose={() => this.setState({isModalOpen: false})}
            style={{
              overlay: {
                zIndex: 99999,
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(255, 255, 255, 1)",
              },
              content: {
                zIndex: 99999,
                opacity: 0.95,
                position: "absolute",
                top: "0px",
                left: "0px",
                right: "0px",
                bottom: "0px",
                background: "#fff",
                overflow: "auto",
                WebkitOverflowScrolling: "touch",
                outline: "none",
                padding: "20px",
              },
            }}
            contentLabel="Newsletter"
          > 
            <div className="flex flex-row justify-between mt-4 content-center">
              <div className="h-16 w-16">
                <Link to="/">
                  <Logo />
                </Link>
              </div>
              <a className="flex justify-end h-16 w-16 cursor-pointer" onClick={this.closeModal}>
                <IoIosClose size={32} />
              </a>
            </div>
            <div class="flex">
              <div class="margin-auto mt-8 justify-center"> 
                <h2 className="font-headline text-4xl font-black leading-tight text-center">Brickmark Newsletter</h2>
                <MailchimpForm mode="dark"/>
              </div>
            </div>
          </Modal>
      </div>
    ) : (
      <div className="tile">
        <div className="flex flex-row xl:pr-64 xl:pl-64 lg:pr-32 lg:pl-32 h-screen">
          <div className="self-center z-10">
            <h1 className="font-headline text-6xl font-black leading-tight">
              <nobr>Next Level</nobr>
              <br />
              <nobr>Real Estate</nobr>
              <br />
              Investments
            </h1>
            <p className="mt-8 md:w-4/5 lg:w-10/12 lg:text-lg font-body font-light text-base z-10">
              Providing universal access to the world’s premier real estate in a
              secure and efficient way.
            </p>
            <div class="mt-8">
              <Link to="/portfolio">
                <span class="font-body text-brickmark-orange font-medium text-lg border-solid border-brickmark-orange border rounded-lg p-3">
                  See Our Portfolio
                </span>
              </Link>              
            </div>
            <div class="mt-4 z-10">              
              <span class="ml-1 font-body text-brickmark-orange text-sm cursor-pointer" onClick={this.openModal}>
                Or subscribe to our newsletter
              </span>                
            </div>
          </div>
          <div
            className="self-center z-0"
            style={{ transform: `translateX(${40 + offset / 10}px)` }}
          >
            <img className="float-right max-w-3xl" src={maskLogo} />
          </div>
        </div>
          <Modal
            isOpen={this.state.isModalOpen}
            onRequestClose={this.closeModal}
            style={{
              overlay: {
                zIndex: 99999,
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(255, 255, 255, 1)",
              },
              content: {
                zIndex: 99999,
                opacity: 0.95,
                position: "absolute",
                top: "0px",
                left: "0px",
                right: "0px",
                bottom: "0px",
                background: "#fff",
                overflow: "auto",
                WebkitOverflowScrolling: "touch",
                outline: "none",
                padding: "20px",
              },
            }}
            contentLabel="Newsletter"
          > 
            <div className="flex flex-row justify-between mt-4 content-center">
              <div className="h-20 w-20">
                <Link to="/">
                  <Logo />
                </Link>
              </div>
              <a className="flex justify-end h-16 w-16 cursor-pointer" onClick={this.closeModal}>
                <IoIosClose size={48} />
              </a>
            </div>
            <div class="flex">
              <div class="m-auto mt-8 justify-center"> 
                <h2 className="font-headline text-4xl font-black leading-tight text-center">Brickmark Newsletter</h2>
                <MailchimpForm mode="dark"/>
              </div>
            </div>
          </Modal>
      </div>      
    )
  }
}

export default Mission
