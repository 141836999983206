import React from "react"
import { FaLink } from "react-icons/fa"

class Presse extends React.PureComponent {
  render() {
    const { isMobile } = this.props
    return isMobile ? (
      <div className="container w-screen bg-brickmark-grey mt-48 relative" id="press">
        <div
          className="bg-brickmark-grey absolute w-screen"
          style={{ transform: "skewY(-7deg)", top: "-5vh", height: 1000 }}
        />
        <div className="flex flex-col pl-8 pr-8">
          <h2 className="font-headline text-6xl md:text-5xl md:mt-24 font-black leading-tight pb-12 z-10">
            Press releases.
          </h2>
          <div className="flex mt-24">
            <div
              className="divTable z-10 w-full"
              style={{
                border: "1px solid rgb(242,242,242)",
              }}
            >
              <div className="divTableBody">
                <a href="https://medium.com/brickmark-ag/leading-fintech-influencer-efi-pylarinou-joins-brickmarks-advisory-board-14e6456b4ad4" className="divTableRow" target="_blank">
                  <div
                    className="divTableCell divTableCellLink rightNone p-6"
                    style={{ width: 80 }}
                  >
                    <FaLink style={{ margin: "auto" }} />
                  </div>
                  <div className="divTableCell pl-6">
                    24.02.2020 - Leading FinTech Influencer Efi Pylarinou joins BrickMark’s Advisory Board
                  </div>
                </a>
                <a href="https://medium.com/brickmark-ag/brickmark-henning-gebhardt-strengthens-high-caliber-advisory-board-9d2cccce68c5" className="divTableRow" target="_blank">
                  <div
                    className="divTableCell divTableCellLink rightNone p-6"
                    style={{ width: 80 }}
                  >
                    <FaLink style={{ margin: "auto" }} />
                  </div>
                  <div className="divTableCell pl-6">
                    19.02.2020 - Henning Gebhardt strengthens high-caliber Advisory Board
                  </div>
                </a>
                <a href="https://medium.com/brickmark-ag/brickmark-signs-purchase-agreement-for-the-largest-ever-real-estate-transaction-paid-in-tokens-of-13a6195cb303" className="divTableRow" target="_blank">
                  <div
                    className="divTableCell divTableCellLink rightNone p-6"
                    style={{ width: 80 }}
                  >
                    <FaLink style={{ margin: "auto" }} />
                  </div>
                  <div className="divTableCell pl-6">
                    15.01.2020 - BrickMark signs purchase agreement for the largest ever real estate transaction paid in tokens of a total volume of over CHF 130 million
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div className="tile bg-brickmark-grey relative ml-8">
        <div
          className="absolute bg-brickmark-grey h-screen"
          style={{
            left: -300,
            transform:
              "matrix3d(1, 0, 0, 0, -0.57735, 1, 0, 0, 0, 0, 1, 0, -81.1415, 0, 0, 1)",
            width: "1200px",
          }}
        />
        <div className="flex flex-col h-screen overflow-hidden z-10 self-center">
          <h2 className="font-headline text-6xl md:text-5xl md:mt-24 font-black leading-tight pb-12 z-10">
            Press releases.
          </h2>
          <div className="flex pt-12">
            <div
              className="divTable z-10 w-7/12"
              style={{
                border: "1px solid rgb(242,242,242)",
              }}
            >
              <div className="divTableBody">
                <a href="https://medium.com/brickmark-ag/leading-fintech-influencer-efi-pylarinou-joins-brickmarks-advisory-board-14e6456b4ad4" className="divTableRow" target="_blank">
                  <div
                    className="divTableCell divTableCellLink rightNone p-6"
                    style={{ width: 80 }}
                  >
                    <FaLink style={{ margin: "auto" }} />
                  </div>
                  <div className="divTableCell pl-6">
                    24.02.2020 - Leading FinTech Influencer Efi Pylarinou joins BrickMark’s Advisory Board
                  </div>
                </a>
                <a href="https://medium.com/brickmark-ag/brickmark-henning-gebhardt-strengthens-high-caliber-advisory-board-9d2cccce68c5" className="divTableRow" target="_blank">
                  <div
                    className="divTableCell divTableCellLink rightNone p-6"
                    style={{ width: 80 }}
                  >
                    <FaLink style={{ margin: "auto" }} />
                  </div>
                  <div className="divTableCell pl-6">
                    19.02.2020 - Henning Gebhardt strengthens high-caliber Advisory Board
                  </div>
                </a>
                <a href="https://medium.com/brickmark-ag/brickmark-signs-purchase-agreement-for-the-largest-ever-real-estate-transaction-paid-in-tokens-of-13a6195cb303" className="divTableRow" target="_blank">
                  <div
                    className="divTableCell divTableCellLink rightNone p-6"
                    style={{ width: 80 }}
                  >
                    <FaLink style={{ margin: "auto" }} />
                  </div>
                  <div className="divTableCell pl-6">
                    15.01.2020 - BrickMark signs purchase agreement for the largest ever real estate transaction paid in tokens of a total volume of over CHF 130 million
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Presse
