import React from "react"
import { push as MobileMenu } from "react-burger-menu"


class Menu extends React.PureComponent {
  render() {
    const { isMobile, width, height, offset, scrollToValue } = this.props

    const calcAbs = value => {
      const tempValue = Math.abs(offset) - value
      return tempValue >= 0 ? tempValue : 0
    }

    const displaysSpecialMenu = isMobile === false && window.innerHeight < 780;

    const pointOfMission = width
    const pointOfWhatWeDo = width <= 1700 ? 1700 : width
    const pointOfPortfolio = 1400
    const pointOfTeam = width * 2 <= 3800 ? 3800 : width * 2
    const pointOfNews = width - 400
    const pointOfContact = width / 2 <= 900 ? 900 : width / 2
    const pointOfPress = width - 600
    return isMobile === true || window.innerHeight < 780 ? (
      <MobileMenu
            right
            disableAutoFocus

            styles={{
              bmBurgerButton: {
                // border: "1px solid white",
                //borderRadius: "50%",
                position: "fixed",
                width: "30px",
                height: "30px",
                right: "36px",
                bottom: "36px",
                // padding: 10,
                overflow: "hidden",
              },
              bmBurgerBars: {
                backgroundColor: "#373a47",
              },
              bmBurgerBarsHover: {
                backgroundColor: "#df5f2b !important",
                // border: "1px solid orange",
              },
              bmCrossButton: {
                height: "30px",
                width: "30px",
                position: "fixed",
                // width: "36px",
                // height: "30px",
                top: null,
                right: "24px",
                bottom: "32px",
              },
              bmCross: {
                zIndex: 9,
                background: "#bdc3c7",
                position: "fixed",
                // width: "36px",
                // height: "30px",
                right: "36px",
                bottom: "36px",
              },
              bmMenuWrap: {
                position: "fixed",
                height,
                top: "0px"
              },
              bmMenu: {
                background: "#FFFFFF",
                padding: "2.5em 1.5em 0",
                fontSize: "1.15em",
                position: "relativ",
              },
              bmMorphShape: {
                fill: "#373a47",
              },
              bmItemList: {
                color: "#b8b7ad",
                padding: "0.8em",
              },
              bmItem: {
                display: "block",
                marginBottom: 20,
                cursor: "pointer"
              },
              bmOverlay: {
                background: "rgba(0, 0, 0, 0.3)",
                top: "0px"
              },
            }}
          >
            { displaysSpecialMenu === true ? (
              <ul>
                <li>
                  <a className="menu-item font-headline text-2xl font-black leading-tight text-black" onClick={() => scrollToValue(1)}>
                    Mission
                  </a>
                </li>
                <li>
                  <a className="menu-item font-headline text-2xl font-black leading-tight text-black" onClick={() => scrollToValue(-pointOfMission)}>
                    What we do
                  </a>
                </li>
                <li>
                  <a className="menu-item font-headline text-2xl font-black leading-tight text-black" onClick={() => scrollToValue(-pointOfMission - pointOfPortfolio)}>
                    Portfolio
                  </a>
                </li>
                <li>
                  <a className="menu-item font-headline text-2xl font-black leading-tight text-black" onClick={() => scrollToValue(
                    -pointOfMission - pointOfWhatWeDo - pointOfPortfolio
                  )}>
                    Team
                  </a>
                </li>
                <li>
                  <a className="menu-item font-headline text-2xl font-black leading-tight text-black" onClick={() => scrollToValue(
                    -pointOfMission -
                      pointOfWhatWeDo -
                      pointOfPortfolio -
                      pointOfTeam
                  )}>
                    Publications
                  </a>
                </li>
                <li>
                  <a className="menu-item font-headline text-2xl font-black leading-tight text-black" onClick={() => scrollToValue(
                    -pointOfMission -
                      pointOfWhatWeDo -
                      pointOfPortfolio -
                      pointOfTeam -
                      pointOfNews -
                      pointOfPress
                  )}>
                    Press
                  </a>
                </li>
                <li>
                  <a className="menu-item font-headline text-2xl font-black leading-tight text-black" onClick={() => scrollToValue(
                    -pointOfMission -
                      pointOfWhatWeDo -
                      pointOfPortfolio -
                      pointOfTeam -
                      pointOfNews -
                      pointOfPress -
                      pointOfContact
                  )}>
                    Contact
                  </a>
                </li>
              </ul>
            ) : (
              <ul>
                <li>
                  <a className="menu-item font-headline text-2xl font-black leading-tight text-black" href="#mission">
                    Mission
                  </a>
                </li>
                <li>
                  <a className="menu-item font-headline text-2xl font-black leading-tight text-black" href="#whatwedo">
                    What we do
                  </a>
                </li>
                <li>
                  <a className="menu-item font-headline text-2xl font-black leading-tight text-black" href="#portfolio">
                    Portfolio
                  </a>
                </li>
                <li>
                  <a className="menu-item font-headline text-2xl font-black leading-tight text-black" href="#team">
                    Team
                  </a>
                </li>
                <li>
                  <a className="menu-item font-headline text-2xl font-black leading-tight text-black" href="#news">
                    Publications
                  </a>
                </li>
                <li>
                  <a className="menu-item font-headline text-2xl font-black leading-tight text-black" href="#press">
                    Press
                  </a>
                </li>
                <li>
                  <a className="menu-item font-headline text-2xl font-black leading-tight text-black" href="#footer">
                    Contact
                  </a>
                </li>
              </ul>
            )}

          </MobileMenu>
    ) : (
      <ul
        style={{
          position: "absolute",
          cursor: "pointer",
          width: 900,
          height: 32,
          zIndex: 999,
          bottom: 25,
          left: width / 2 - 400
        }}
      >
        <li
          style={{
            display: "inline-block",
            width: 127,
            overflow: "hidden",
            textAlign: "left",
          }}
          className={`bm-menu-nav-item ${Math.abs(offset) >= 0 && "-active"}`}
        >
          <a onClick={() => scrollToValue(1)}>
            <div className="bm-menu-nav-item-title">Vision</div>
            <div className="bm-menu-nav-item-line">
              <div className="bm-menu-nav-item-sec">
                <span
                  style={{
                    width: `${Math.floor(
                      (Math.abs(offset) / pointOfMission) * 100
                    )}%`,
                  }}
                ></span>
              </div>
            </div>
          </a>
        </li>

        <li
          style={{
            display: "inline-block",
            width: 127,
            overflow: "hidden",
            textAlign: "left",
          }}
          className="bm-menu-nav-item"
          className={`bm-menu-nav-item ${Math.abs(offset) >= width &&
            "-active"}`}
        >
          <a onClick={() => scrollToValue(-pointOfMission)}>
            <div className="bm-menu-nav-item-title">What we do</div>
            <div className="bm-menu-nav-item-line">
              <div className="bm-menu-nav-item-sec">
                <span
                  style={{
                    width: `${Math.floor(
                      (calcAbs(pointOfMission) / pointOfWhatWeDo) * 100
                    )}%`,
                  }}
                ></span>
              </div>
            </div>
          </a>
        </li>
        <li
          style={{
            display: "inline-block",
            width: 127,
            overflow: "hidden",
            textAlign: "left",
          }}
          className={`bm-menu-nav-item ${Math.abs(offset) >=
            pointOfMission + pointOfWhatWeDo && "-active"}`}
        >
          <a onClick={() => scrollToValue(-pointOfMission - pointOfWhatWeDo)}>
            <div className="bm-menu-nav-item-title">Portfolio</div>
            <div className="bm-menu-nav-item-line">
              <div className="bm-menu-nav-item-sec">
                <span
                  style={{
                    width: `${Math.floor(
                      (calcAbs(pointOfMission + pointOfWhatWeDo) /
                        pointOfPortfolio) *
                        100
                    )}%`,
                  }}
                ></span>
              </div>
            </div>
          </a>
        </li>
        <li
          style={{
            display: "inline-block",
            width: 127,
            overflow: "hidden",
            textAlign: "left",
          }}
          className={`bm-menu-nav-item ${Math.abs(offset) >=
            pointOfMission + pointOfWhatWeDo + pointOfPortfolio && "-active"}`}
        >
          <a
            onClick={() =>
              scrollToValue(
                -pointOfMission - pointOfWhatWeDo - pointOfPortfolio
              )
            }
          >
            <div className="bm-menu-nav-item-title">Team</div>
            <div className="bm-menu-nav-item-line">
              <div className="bm-menu-nav-item-sec">
                <span
                  style={{
                    width: `${Math.floor(
                      (calcAbs(
                        pointOfMission + pointOfWhatWeDo + pointOfPortfolio
                      ) /
                        pointOfTeam) *
                        100
                    )}%`,
                  }}
                ></span>
              </div>
            </div>
          </a>
        </li>
        <li
          style={{
            display: "inline-block",
            width: 127,
            overflow: "hidden",
            textAlign: "left",
          }}
          className={`bm-menu-nav-item ${Math.abs(offset) >=
            pointOfMission + pointOfWhatWeDo + pointOfPortfolio + pointOfTeam &&
            "-active"}`}
        >
          <a
            onClick={() =>
              scrollToValue(
                -pointOfMission -
                  pointOfWhatWeDo -
                  pointOfPortfolio -
                  pointOfTeam
              )
            }
          >
            <div className="bm-menu-nav-item-title">Publications</div>
            <div className="bm-menu-nav-item-line">
              <div className="bm-menu-nav-item-sec">
                <span
                  style={{
                    width: `${Math.floor(
                      (calcAbs(
                        pointOfMission +
                          pointOfWhatWeDo +
                          pointOfPortfolio +
                          pointOfTeam
                      ) /
                        pointOfNews) *
                        100
                    )}%`,
                  }}
                ></span>
              </div>
            </div>
          </a>
        </li>
        <li
          style={{
            display: "inline-block",
            width: 127,
            overflow: "hidden",
            textAlign: "left",
          }}
          className={`bm-menu-nav-item ${Math.abs(offset) >=
            pointOfMission + pointOfWhatWeDo + pointOfPortfolio + pointOfTeam + pointOfPress &&
            "-active"}`}
        >
          <a
            onClick={() =>
              scrollToValue(
                -pointOfMission -
                  pointOfWhatWeDo -
                  pointOfPortfolio -
                  pointOfTeam -
                  pointOfNews -
                  pointOfPress
              )
            }
          >
            <div className="bm-menu-nav-item-title">Press</div>
            <div className="bm-menu-nav-item-line">
              <div className="bm-menu-nav-item-sec">
                <span
                  style={{
                    width: `${Math.floor(
                      (calcAbs(
                        pointOfMission +
                          pointOfWhatWeDo +
                          pointOfPortfolio +
                          pointOfTeam +
                          pointOfNews +
                          pointOfPress
                      ) /
                        pointOfContact) *
                        100
                    )}%`,
                  }}
                ></span>
              </div>
            </div>
          </a>
        </li>
        <li
          style={{
            display: "inline-block",
            width: 127,
            overflow: "hidden",
            textAlign: "left",
            verticalAlign: "top",
          }}
          className={`bm-menu-nav-item ${Math.abs(offset - 100) >=
            pointOfMission +
              pointOfWhatWeDo +
              pointOfPortfolio +
              pointOfTeam +
              pointOfNews +
              pointOfPress + pointOfContact && "-active"}`}
        >
          <a
            onClick={() =>
              scrollToValue(
                -pointOfMission -
                  pointOfWhatWeDo -
                  pointOfPortfolio -
                  pointOfTeam -
                  pointOfNews -
                  pointOfPress -
                  pointOfContact
              )
            }
          >
            <div className="bm-menu-nav-item-title">Contact</div>
          </a>
        </li>
      </ul>
    )
  }
}

export default Menu
