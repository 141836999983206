import React from "react"

import background from '../assets/images/7.jpg'
import { Link } from "gatsby"

class Portfolio extends React.PureComponent {
  render() {
    const { isMobile, offset } = this.props
    return isMobile ? (
      <div className="container w-screen pt-8 pb-8 mt-48 relative z-10" id="portfolio">
        <div
          className="bg-brickmark-blue absolute w-screen"
          style={{ transform: "skewY(-7deg)", top: "-5vh", height: 400 }}
        />

        <div className="w-screen" style={{ height: 300 }}>
          <div className="pl-8 pr-8 mt-8">
            <Link to="/portfolio">
              <h2 className="font-headline text-5xl md:text-5xl font-black leading-tight text-white opacity-90">
                Portfolio
              </h2>              
            </Link>
            <p className="mt-8 font-body font-light text-lg opacity-90 text-white">
              BrickMark is currently in due diligence for a EUR 1bn pipeline of
              prime commercial property acquisition opportunities in the EU.
            </p>
            <div class="mt-8">
              <Link to="/portfolio">
                <span class="font-body text-brickmark-orange font-medium text-lg border-solid border-brickmark-orange border rounded-lg p-3 opacity-90">
                  See Our Portfolio
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div className="bg-brickmark-blue relative ml-64" style={{minWidth: '1400px'}}>
        <div
          className="absolute bg-brickmark-blue h-screen"
          style={{
            left: -300,
            transform:
              "matrix3d(1, 0, 0, 0, -0.57735, 1, 0, 0, 0, 0, 1, 0, -81.1415, 0, 0, 1)",
            width: '1200px'
          }}
        />
        <div className="flex h-screen overflow-hidden">
          <div className="self-center pl-16 pr-16 relative">
            <div
              className="absolute opacity-50"
              style={{
                top: -600,
                right: -700,
                width: 1200,
                zIndex: 0,
                transform: `translateX(${offset / 10}px)`,
              }}
              // style={{ transform: `translateX(${offset / 10}px)` }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1100 1477">
                <defs>
                  <mask id="portfolio-lines-mask">
                    <g
                      data-svg-origin="1 0.10999999940395355"
                      transform="matrix(1,0,0,1,122.22877370034146,0)"
                      style={{ zIndex: 0 }}
                    >
                      <path
                        d="M575.5,1219.78,427.06,1476.89,1,739.64,149.42,482.52Z"
                        fill="#fff"
                      ></path>
                      <path
                        d="M854,737.36,705.59,994.48,279.5,257.22,427.94.11Z"
                        fill="#fff"
                      ></path>
                    </g>
                  </mask>
                </defs>
                <image
                  xlinkHref={background}
                  width="1300"
                  height="1836"
                  mask="url(#portfolio-lines-mask)"
                  x="0"
                  y="0"
                />
              </svg>
            </div>
            <Link to="/portfolio">
              <h2
                className="font-headline font-black leading-tight text-white text-left opacity-90 z-10"
                style={{ fontSize: 182 }}
              >
                Portfolio.
              </h2>              
            </Link>
            <p
              className="mt-4 font-body font-light text-base text-left opacity-90 text-white lg:text-lg pl-4"
              style={{ width: 470 }}
            >
              BrickMark is currently in due diligence for a EUR 1bn pipeline of
              prime commercial property acquisition opportunities in the EU.
            </p>
            <div className="mt-8">
              <Link to="/portfolio">
                <span class="font-body text-brickmark-orange font-medium text-lg border-solid border-brickmark-orange border rounded-lg p-3 ml-4">
                  See Our Portfolio
                </span>            
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Portfolio
