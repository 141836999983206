import React, { PureComponent } from "react"
import { UserAgent } from "react-useragent"

export default function withWindowDimensions(Component) {
  return class extends PureComponent {
    state = { width: 0, height: 0 }

    componentDidMount() {
      this.updateWindowDimensions()
      window.addEventListener("resize", this.updateWindowDimensions)
    }

    componentWillUnmount() {
      window.removeEventListener("resize", this.updateWindowDimensions)
    }

    updateWindowDimensions = () => {
      this.setState({ width: window.innerWidth, height: window.innerHeight })
    }

    render() {
      return typeof window !== "undefined" ? (
        <UserAgent
          render={({ ua }) => (
            <Component
              {...this.props}
              width={this.state.width}
              height={this.state.height}
              // isMobile={ua.mobile !== null || this.state.width < 1024}
              isMobile={this.state.width < 1024}
            />
          )}
        />
      ) : (
        <Component
          {...this.props}
          width={this.state.width}
          height={this.state.height}
          isMobile={this.state.width < 1024}
        />
      )
    }
  }
}
