import React from "react"
import Modal from "react-modal"
import { Link } from "gatsby"
import CountUp from "react-countup"
import VisibilitySensor from "react-visibility-sensor"

import Logo from "../components/logo"

import stephanRind from "../assets/images/team/1.jpg"
import christian from "../assets/images/team/2.jpg"
import mark from "../assets/images/team/3.jpg"
import pavel from "../assets/images/team/4.jpg"
import roger from "../assets/images/team/5.jpg"
import alex from "../assets/images/team/6.jpg"
import john from "../assets/images/team/7.jpg"
import luciano from "../assets/images/team/9.jpg"
import danilo from "../assets/images/team/10.jpg"
import efi from "../assets/images/team/11.jpg"
import gebhardt from "../assets/images/team/12.jpg"
import marcello from "../assets/images/team/13.jpg"
import cryptoValley from "../assets/images/partners/crypto-valley.png"
import erpa from "../assets/images/partners/erpa.png"
import swisscom from "../assets/images/partners/swisscom.png"

import { FaLinkedinIn } from "react-icons/fa"
import { IoIosClose } from "react-icons/io"

Modal.setAppElement("#___gatsby")

class Team extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isModalOpen: false,
      person: {},
      y: 0,
      firstCounter: false,
      secondCounter: false,
    }
  }

  openModal = person => {
    this.setState({ isModalOpen: true, person })
  }

  closeModal = event => {
    this.setState({ isModalOpen: false, person: {} })
  }

  onVisibilityChange = isVisible => {
    if (isVisible) {
      this.setState({ firstCounter: true, secondCounter: true })
    }
  }

  render() {
    const { isMobile } = this.props
    return (
      <div className={`${isMobile ? "" : "tile-double"}`} id="team">
        <Modal
          isOpen={this.state.isModalOpen}
          onRequestClose={this.closeModal}
          style={{
            overlay: {
              zIndex: 99999,
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(255, 255, 255, 1)",
            },
            content: {
              zIndex: 99999,
              opacity: 0.95,
              position: "absolute",
              top: "0px",
              left: "0px",
              right: "0px",
              bottom: "0px",
              background: "#fff",
              overflow: "auto",
              WebkitOverflowScrolling: "touch",
              outline: "none",
              padding: "20px",
            },
          }}
          contentLabel="Person"
        >
          <div className="flex flex-row justify-between mt-4 content-center">
            <div className="h-16 w-16">
              <Link to="/">
                <Logo />
              </Link>
            </div>
            <a className="flex justify-end h-16 w-16" onClick={this.closeModal}>
              <IoIosClose size={32} />
            </a>
          </div>

          <div style={{ padding: isMobile ? 0 : 200 }}>
            <h3 className="mt-12 mb-2 font-semibold">{this.state.person.title} </h3>
            <p>{this.state.person.description}</p>
            {this.state.person.linkedIn && (
              <a
                className="mt-4 rounded-full w-8 h-8 flex items-center justify-center border cursor-pointer"
                href={this.state.person.linkedIn}
                target="_blank"
              >
                <div className="">
                  <FaLinkedinIn />
                </div>
              </a>
            )}
          </div>
        </Modal>
        {isMobile ? (
          <div className="z-0 bg-brickmark-grey w-screen pt-24 pb-8 -mt-12 relative md:pt-48 md:-mt-24">
            <div className=" w-screen">
              <div className="pl-8 pr-8 pt-8">
                <h2 className="font-headline text-4xl md:text-5xl font-black leading-tight">
                  Team.
                </h2>
                <h3 className="mt-10 font-body text-base uppercase font-semibold tracking-wider">
                  Management team
                </h3>
                <p className="mt-6 font-body font-light text-lg opacity-70 md:w-4/5">
                  The management team brings together highly experienced
                  professionals of diverse backgrounds, with individual
                  specializations in all core aspects of the BrickMark AG
                  business model.
                </p>
                <h3 className="mt-8 mb-2 font-body text-base uppercase font-semibold tracking-wider">
                  Property Value Managed
                </h3>
                <VisibilitySensor
                  onChange={this.onVisibilityChange}
                  offset={{
                    top: 10,
                  }}
                  delayedCall
                >
                  <div>
                    <p className="font-light font-body text-brickmark-orange text-5xl">
                      <CountUp
                        start={0}
                        prefix="€"
                        suffix="bn"
                        duration={2}
                        end={this.state.firstCounter ? 15 : 0}
                      />
                    </p>
                    <h3 className="mt-8 mb-2 font-body text-base uppercase font-semibold tracking-wider">
                      Tech Companies Scaled
                    </h3>
                    <p className="font-light font-body text-brickmark-orange text-5xl">
                      <CountUp
                        start={0}
                        end={this.state.secondCounter ? 25 : 0}
                      />
                    </p>
                  </div>
                </VisibilitySensor>
                <div className="flex flex-row flex-wrap mt-8 mb-8 justify-center md:justify-start md:-ml-4">
                  <a
                    onClick={() =>
                      this.openModal({
                        title: "Stephan Rind",
                        description:
                          "Entrepreneur and business angel, Stephan Rind started as an investment banker in New York and Dusseldorf. In 2002 he founded Colonia Real Estate and built up the company to be the 3rd largest listed residential real estate company in Germany. The company was bought by TAG in 2011.",
                        linkedIn:
                          "https://www.linkedin.com/in/stephan-rind-35683152/",
                      })
                    }
                    className="m-4 cursor-pointer"
                  >
                    <img
                      src={stephanRind}
                      className="contain w-32 md:w-40 mb-1"
                    />
                    <span className="font-body opacity-50 text-base">
                      Stephan Rind
                    </span>
                  </a>
                  <a
                    onClick={() =>
                      this.openModal({
                        title: "Mark Abramson",
                        description:
                          "Mark Abramson has spent the past 25 years as a senior professional in global equities at a number of large international financial institutions, including as Senior Managing Director at Bear Stearns. Most recently, he was co-Head of global REIT investments at Heitman LLC serving from 2005 to 2017. He is an independent director and external advisor to public and private real estate and investment companies.",
                        linkedIn: null,
                      })
                    }
                    className="m-4 cursor-pointer"
                  >
                    <img src={mark} className="contain w-32 md:w-40 mb-1" />
                    <span className="font-body opacity-50 text-base">
                      Mark Abramson
                    </span>
                  </a>
                  <a
                    onClick={() =>
                      this.openModal({
                        title: "Marcello Ardizzone",
                        description:
                          "Marcello is a passionate Software Architect and Senior IT Consultant, focusing on distributed ledger technology and its applications for real word use cases. During the last years, Marcello has been contributing to build tokenization and compliance software platforms, and a fully integrated issuance, trading, settlement and custody infrastructure for digital assets within a blockchain ecosystem where Swisscom Schweiz AG and Swiss banks are deeply involved. ",
                        linkedIn:
                          "https://ch.linkedin.com/in/marcello-ardizzone-8ba283a5",
                      })
                    }
                    className="m-4 cursor-pointer"
                  >
                    <img src={marcello} className="contain w-32 md:w-40 mb-1" />
                    <span className="font-body opacity-50 text-base">
                      Marcello Ardizzone
                    </span>
                  </a>
                  <a
                    onClick={() =>
                      this.openModal({
                        title: "Roger Hermann",
                        description:
                          "Roger Hermann has served in various senior positions in the accounting, auditing and financial consultancy industries, including as Head of Financial Reporting for a publicly listed company. He is a certified trustee and auditor in Switzerland. Most recently, Roger was responsible for digitization and start-ups for BDO Switzerland.",
                        linkedIn:
                          "https://www.linkedin.com/in/roger-hermann-4b6465104/",
                      })
                    }
                    className="m-4 cursor-pointer"
                  >
                    <img src={roger} className="contain w-32 md:w-40 mb-1" />
                    <span className="font-body opacity-50 text-base">
                      Roger Hermann
                    </span>
                  </a>
                  <a
                    onClick={() =>
                      this.openModal({
                        title: "Alexander Funck",
                        description:
                          "Alexander Funck is an online marketing specialist, focusing on tech-enabled cross channel marketing and venture building. He has advised clients ranging from renowned start-up ventures to DAX CEOs. Alexander previously co-founded Medabo.de, a med-tech company resolving pharmacy inefficiencies for thousands of European users.",
                        linkedIn: "https://www.linkedin.com/in/alexanderfunck/",
                      })
                    }
                    className="m-4 cursor-pointer"
                  >
                    <img src={alex} className="contain w-32 md:w-40 mb-1" />
                    <span className="font-body opacity-50 text-base">
                      Alexander Funck
                    </span>
                  </a>
                </div>
                <h3 className="mt-10 font-body text-base uppercase font-semibold tracking-wider">
                  Advisory Board
                </h3>
                <p className="mt-6 font-body font-light text-lg opacity-70 md:w-4/5">
                  The Advisory Board consists of the most senior level industry
                  professionals from across the company’s areas of activity,
                  including real estate, finance, legal and technology.
                </p>
                <div className="flex flex-row flex-wrap mt-8 justify-center md:justify-start md:-ml-4">
                  <a
                    onClick={() =>
                      this.openModal({
                        title: "Henning Gebhardt",
                        description:
                          "Henning Gebhardt, 53, had leading positions with DWS for many years. He started his professional career with the fund subsidiary of Deutsche Bank in 1996 as Portfolio Manager International Equities and after having been Head of German Equity and Head of European Equity from 2014 to 2016, he managed as Global Head of Equity the global equity business of DWS, where he managed assets of around EUR 100 billion. After more than 20 years with the fund company Gebhardt joined Berenberg Bank in 2017, where he acted as head for the central unit Wealth and Asset Management. Gebhardt is a multiple award-winning fund manager who boasts a deep expert knowledge on the capital market. In 2016 he was Fund Manager of the Year and in 2017 he was the winner of the renowned Sauren Golden Award.",
                        linkedIn: "https://www.linkedin.com/in/henning-gebhardt-633a8818",
                      })
                    }
                    className="m-4 cursor-pointer"
                  >
                    <img src={gebhardt} className="contain w-32 md:w-40 mb-1" />
                    <span className="font-body opacity-50 text-xs">
                      Henning Gebhardt
                    </span>
                  </a>
                  <a
                    onClick={() =>
                      this.openModal({
                        title: "John Nacos",
                        description:
                          "John Nacos serves as a Board Member of CA Immobilien AG and CR Investment Management. Previously, John was the Global Head of Commercial Real Estate at Deutsche Bank from 2001 to 2011. John worked at Merrill Lynch for eleven years where he established and managed Merrill Lynch´s mortgage financing, opportunistic real estate and MBS/ABS trading activities in Europe.",
                        linkedIn:
                          "https://www.linkedin.com/in/john-nacos-87a77327/",
                      })
                    }
                    className="m-4 cursor-pointer"
                  >
                    <img src={john} className="contain w-32 md:w-40 mb-1" />
                    <span className="font-body opacity-50 text-base">
                      John Nacos
                    </span>
                  </a>
                  <a
                    onClick={() =>
                      this.openModal({
                        title: "Dr. Luciano Gabriel",
                        description:
                          "Luciano Gabriel worked from 1984–1998 at Union Bank of Switzerland where he held various management positions. From 1998–2002 he joined Zurich Financial Services and was responsible for corporate finance and treasury. In 2002 he became CFO of PSP Swiss Property (Switzerland’s 2nd largest listed RE company) and became PSP’s CEO in 2007 until 2017. He is now Chairman of PSP and a Board Member of listed COIMA RES S.pa., Milan.",
                        linkedIn:
                          "https://www.linkedin.com/in/luciano-gabriel-765705b4/",
                      })
                    }
                    className="m-4 cursor-pointer"
                  >
                    <img src={luciano} className="contain w-32 md:w-40 mb-1" />
                    <span className="font-body opacity-50 text-base block w-32">
                      Dr. Luciano Gabriel
                    </span>
                  </a>
                  <a
                    onClick={() =>
                      this.openModal({
                        title: "Pavel Mayer",
                        description:
                          "Pavel Mayer calls himself a Nerd, Entrepreneur, Mad Scientist and Machine Teacher. He co-founded Art+Com and Datango, among other technology start-ups. He’s a well recognized blockchain engineer and an expert in artificial intelligence and machine learning.",
                        linkedIn:
                          "https://www.linkedin.com/in/pavel-mayer-59385514a/",
                      })
                    }
                    className="m-4 cursor-pointer"
                  >
                    <img src={pavel} className="contain w-32 md:w-40 mb-1" />
                    <span className="font-body opacity-50 text-base">
                      Pavel Mayer
                    </span>
                  </a>
                  <a
                    onClick={() =>
                      this.openModal({
                        title: "Danilo Carlucci",
                        description:
                          "Danilo Carlucci is a crypto pioneer, entrepreneur and one of the leading influencers in the blockchain world. After his work at Google from 2016 – 2018 as Community Leader (for Google and YouTube (UK, Ireland) he founded Cryptonews, a leading media outlet for crypto-related news, in 2018. He was CEO and co-founder of amazers.com from 2012 – 2016, a high-growth social platform connecting high profile influencers with their fans.",
                        linkedIn:
                          "https://www.linkedin.com/in/daniloscarlucci/",
                      })
                    }
                    className="m-4 cursor-pointer"
                  >
                    <img src={danilo} className="contain w-32 md:w-40 mb-1" />
                    <span className="font-body opacity-50 text-base">
                      Danilo Carlucci
                    </span>
                  </a>
                  <a
                    onClick={() =>
                      this.openModal({
                        title: "Efi Pylarinou",
                          description:
                            "Efi Pylarinou is a seasoned Wall Street professional who has grown into a global Fintech influencer. She is the No.3 global influencer in the finance sector and No.1 woman influencer, by Refinitiv Global Social Media 2019 and also included in numerous other rankings. She has more than 180,000 Linkedin followers and a domain focus on Digital Wealth Management & Capital Markets. She advises Fintech and Blockchain ventures, and speaks internationally on Fintech and Blockchain trends. She is the co-founder and author of Daily Fintech.",
                          linkedIn:
                            "https://ch.linkedin.com/in/efipylarinou",
                      })
                    }
                    className="m-4 cursor-pointer"
                  >
                    <img src={efi} className="contain w-32 md:w-40 mb-1" />
                    <span className="font-body opacity-50 text-base">
                      Efi Pylarinou
                    </span>
                  </a>
                </div>
                <h3 className="mt-10 font-body text-base uppercase font-semibold tracking-wider">
                  Partners / Associations
                </h3>
                <p className="mt-6 font-body font-light text-lg opacity-70 md:w-4/5">
                  We have partnered with best-in-class service providers to
                  ensure the highest standards are maintained in everything we
                  do.
                </p>
                <p className="mt-6 font-body font-light text-lg opacity-70 md:w-4/5">
                  First Blockchain Real Estate Company which became Member of
                  EPRA (European Public Real Estate Association). Also Member in
                  CVA (Crypto Valley Association) in Switzerland.
                </p>
                <div className="flex flex-row w-full mt-4 mr-8 -ml-4 justify-center">
                  <div className="w-1/2 md:1/3">
                    <a href="https://www.epra.com" target="_blank">
                      <img src={erpa} />
                    </a>
                  </div>
                  <div className="w-1/2 md:1/3">
                    <a href="https://blockchain.swisscom.com" target="_blank">
                      <img src={swisscom} />
                    </a>
                  </div>
                  <div className="hidden md:block md:1/3">
                    <a href="https://cryptovalley.swiss" target="_blank">
                      <img src={cryptoValley} className="pt-3" />
                    </a>
                  </div>
                </div>
                <div className="flex flex-row w-full mr-8 justify-center">
                  <div className="w-1/2 block md:hidden">
                    <a href="https://cryptovalley.swiss" target="_blank">
                      <img src={cryptoValley} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="bg-brickmark-grey">
            <div className="flex flex-row h-screen pl-32">
              <div
                className="self-center w-1/8 mr-12"
              >
                <h2
                  className="font-headline pb-24 font-black md:text-5xl"
                >
                  Team.
                </h2>
                <h3 className="mt-32 font-body uppercase font-semibold tracking-wider">
                  Management team
                </h3>
                <p className="mt-8 font-body font-light opacity-70 text-lg">
                  The management team brings together highly experienced
                  professionals of diverse backgrounds, with individual
                  specializations in all core aspects of the BrickMark AG
                  business model.
                </p>
              </div>

              <div
                className="self-center pr-12 pl-12 w-1/12"
                style={{ minWidth: 300 }}
              >
                <h3 className="mt-8 font-body text-sm uppercase font-semibold tracking-wider">
                  Property Value Managed
                </h3>
                <VisibilitySensor
                  onChange={this.onVisibilityChange}
                  offset={{
                    top: 10,
                  }}
                  delayedCall
                >
                  <div>
                    <p className="font-light font-body text-brickmark-orange text-6xl">
                      <CountUp
                        start={0}
                        prefix="€"
                        suffix="bn"
                        duration={2}
                        end={this.state.firstCounter ? 15 : 0}
                      />
                    </p>
                    <h3 className="mt-32 font-body text-sm uppercase font-semibold tracking-wider">
                      Tech Companies Scaled
                    </h3>
                    <p className="font-light font-body text-brickmark-orange text-6xl">
                      <CountUp
                        start={0}
                        end={this.state.secondCounter ? 25 : 0}
                      />
                    </p>
                  </div>
                </VisibilitySensor>
              </div>
              <div className="flex-col self-center" style={{ minWidth: 700 }}>
                <div className="flex flex-row ml-24 mt-8 mb-8 justify-center md:justify-start">
                  <a
                    onClick={() =>
                      this.openModal({
                        title: "Stephan Rind",
                        description:
                          "Entrepreneur and business angel, Stephan Rind started as an investment banker in New York and Dusseldorf. In 2002 he founded Colonia Real Estate and built up the company to be the 3rd largest listed residential real estate company in Germany. The company was bought by TAG in 2011.",
                        linkedIn:
                          "https://www.linkedin.com/in/stephan-rind-35683152/",
                      })
                    }
                    className="m-4 cursor-pointer"
                  >
                    <img src={stephanRind} className="contain w-48" />
                    <span className="font-body opacity-50 text-xs">
                      Stephan Rind
                    </span>
                  </a>
                  <a
                    onClick={() =>
                      this.openModal({
                        title: "Mark Abramson",
                        description:
                          "Mark Abramson has spent the past 25 years as a senior professional in global equities at a number of large international financial institutions, including as Senior Managing Director at Bear Stearns. Most recently, he was co-Head of global REIT investments at Heitman LLC serving from 2005 to 2017. He is an independent director and external advisor to public and private real estate and investment companies.",
                        linkedIn: null,
                      })
                    }
                    className="m-4 cursor-pointer"
                  >
                    <img src={mark} className="contain w-48" />
                    <span className="font-body opacity-50 text-xs">
                      Mark Abramson
                    </span>
                  </a>
                </div>
                <div className="flex flex-row mt-8 mb-8 justify-center md:justify-start">
                  <a
                    onClick={() =>
                      this.openModal({
                        title: "Marcello Ardizzone",
                        description:
                          "Marcello is a passionate Software Architect and Senior IT Consultant, focusing on distributed ledger technology and its applications for real word use cases. During the last years, Marcello has been contributing to build tokenization and compliance software platforms, and a fully integrated issuance, trading, settlement and custody infrastructure for digital assets within a blockchain ecosystem where Swisscom Schweiz AG and Swiss banks are deeply involved.",
                        linkedIn:
                          "https://ch.linkedin.com/in/marcello-ardizzone-8ba283a5",
                      })
                    }
                    className="m-4 cursor-pointer"
                  >
                    <img src={marcello} className="contain w-48" />
                    <span className="font-body opacity-50 text-xs">
                      Marcello Ardizzone
                    </span>
                  </a>
                  <a
                    onClick={() =>
                      this.openModal({
                        title: "Roger Hermann",
                        description:
                          "Roger Hermann has served in various senior positions in the accounting, auditing and financial consultancy industries, including as Head of Financial Reporting for a publicly listed company. He is a certified trustee and auditor in Switzerland. Most recently, Roger was responsible for digitization and start-ups for BDO Switzerland.",
                        linkedIn:
                          "https://www.linkedin.com/in/roger-hermann-4b6465104/",
                      })
                    }
                    className="m-4 cursor-pointer"
                  >
                    <img src={roger} className="contain w-48" />
                    <span className="font-body opacity-50 text-xs">
                      Roger Hermann
                    </span>
                  </a>
                  <a
                    onClick={() =>
                      this.openModal({
                        title: "Alexander Funck",
                        description:
                          "Alexander Funck is an online marketing specialist, focusing on tech-enabled cross channel marketing and venture building. He has advised clients ranging from renowned start-up ventures to DAX CEOs. Alexander previously co-founded Medabo.de, a med-tech company resolving pharmacy inefficiencies for thousands of European users.",
                        linkedIn: "https://www.linkedin.com/in/alexanderfunck/",
                      })
                    }
                    className="m-4 mr-24 cursor-pointer"
                  >
                    <img src={alex} className="contain w-48" />
                    <span className="font-body opacity-50 text-xs">
                      Alexander Funck
                    </span>
                  </a>
                </div>
              </div>
              <div
                className="flex-col self-center mt-64 w-2/12 pr-12 pl-12"
                style={{ minWidth: 300 }}
              >
                <h3 className="font-body uppercase font-semibold tracking-wider">
                  Advisory Board
                </h3>
                <p className="mt-6 font-body font-light opacity-70 md:w-4/5 text-lg">
                  The Advisory Board consists of the most senior level industry
                  professionals from across the company’s areas of activity,
                  including real estate, finance, legal and technology.
                </p>
              </div>

              <div className="flex-col self-center" style={{ minWidth: 600 }}>
                <div className="flex flex-col">
                  <div className="flex flex-row ml-24 mt-8 justify-center md:justify-start">
                    <a
                      onClick={() =>
                        this.openModal({
                          title: "Henning Gebhardt",
                          description:
                            "Henning Gebhardt, 53, had leading positions with DWS for many years. He started his professional career with the fund subsidiary of Deutsche Bank in 1996 as Portfolio Manager International Equities and after having been Head of German Equity and Head of European Equity from 2014 to 2016, he managed as Global Head of Equity the global equity business of DWS, where he managed assets of around EUR 100 billion. After more than 20 years with the fund company Gebhardt joined Berenberg Bank in 2017, where he acted as head for the central unit Wealth and Asset Management. Gebhardt is a multiple award-winning fund manager who boasts a deep expert knowledge on the capital market. In 2016 he was Fund Manager of the Year and in 2017 he was the winner of the renowned Sauren Golden Award.",
                          linkedIn: "https://www.linkedin.com/in/henning-gebhardt-633a8818",
                        })
                      }
                      className="m-4 cursor-pointer"
                    >
                      <img src={gebhardt} className="contain w-48 " />
                      <span className="font-body opacity-50 text-xs">
                        Henning Gebhardt
                      </span>
                    </a>
                    <a
                      onClick={() =>
                        this.openModal({
                          title: "John Nacos",
                          description:
                            "John Nacos serves as a Board Member of CA Immobilien AG and CR Investment Management. Previously, John was the Global Head of Commercial Real Estate at Deutsche Bank from 2001 to 2011. John worked at Merrill Lynch for eleven years where he established and managed Merrill Lynch´s mortgage financing, opportunistic real estate and MBS/ABS trading activities in Europe.",
                          linkedIn:
                            "https://www.linkedin.com/in/john-nacos-87a77327/",
                        })
                      }
                      className="m-4 cursor-pointer"
                    >
                      <img src={john} className="contain w-48" />
                      <span className="font-body opacity-50 text-xs">
                        John Nacos
                      </span>
                    </a>
                    <a
                      onClick={() =>
                        this.openModal({
                          title: "Dr. Luciano Gabriel",
                          description:
                            "Luciano Gabriel worked from 1984–1998 at Union Bank of Switzerland where he held various management positions. From 1998–2002 he joined Zurich Financial Services and was responsible for corporate finance and treasury. In 2002 he became CFO of PSP Swiss Property (Switzerland’s 2nd largest listed RE company) and became PSP’s CEO in 2007 until 2017. He is now Chairman of PSP and a Board Member of listed COIMA RES S.pa., Milan.",
                          linkedIn:
                            "https://www.linkedin.com/in/luciano-gabriel-765705b4/",
                        })
                      }
                      className="m-4 cursor-pointer"
                    >
                      <img src={luciano} className="contain w-48" />
                      <span className="font-body opacity-50 text-xs">
                        Dr. Luciano Gabriel
                      </span>
                    </a>
                  </div>
                  <div className="flex flex-row mt-8 justify-center md:justify-start">
                  <a
                    onClick={() =>
                      this.openModal({
                        title: "Pavel Mayer",
                        description:
                          "Pavel Mayer calls himself a Nerd, Entrepreneur, Mad Scientist and Machine Teacher. He co-founded Art+Com and Datango, among other technology start-ups. He’s a well recognized blockchain engineer and an expert in artificial intelligence and machine learning.",
                        linkedIn:
                          "https://www.linkedin.com/in/pavel-mayer-59385514a/",
                      })
                    }
                    className="m-4 cursor-pointer"
                  >
                    <img src={pavel} className="contain w-48" />
                    <span className="font-body opacity-50 text-xs">
                      Pavel Mayer
                    </span>
                  </a>
                    <a
                      onClick={() =>
                        this.openModal({
                          title: "Danilo Carlucci",
                          description:
                            "Danilo Carlucci is a crypto pioneer, entrepreneur and one of the leading influencers in the blockchain world. After his work at Google from 2016 – 2018 as Community Leader (for Google and YouTube (UK, Ireland) he founded Cryptonews, a leading media outlet for crypto-related news, in 2018. He was CEO and co-founder of amazers.com from 2012 – 2016, a high-growth social platform connecting high profile influencers with their fans.",
                          linkedIn:
                            "https://www.linkedin.com/in/daniloscarlucci/",
                        })
                      }
                      className="m-4 cursor-pointer"
                    >
                      <img src={danilo} className="contain w-48" />
                      <span className="font-body opacity-50 text-xs">
                        Danilo Carlucci
                      </span>
                    </a>
                    <a
                      onClick={() =>
                        this.openModal({
                          title: "Efi Pylarinou ",
                          description:
                            "Efi Pylarinou is a seasoned Wall Street professional who has grown into a global Fintech influencer. She is the No.3 global influencer in the finance sector and No.1 woman influencer, by Refinitiv Global Social Media 2019 and also included in numerous other rankings. She has more than 180,000 Linkedin followers and a domain focus on Digital Wealth Management & Capital Markets. She advises Fintech and Blockchain ventures, and speaks internationally on Fintech and Blockchain trends. She is the co-founder and author of Daily Fintech.",
                          linkedIn:
                            "https://ch.linkedin.com/in/efipylarinou",
                        })
                      }
                      className="m-4 mr-24 cursor-pointer"
                    >
                      <img src={efi} className="contain w-48" />
                      <span className="font-body opacity-50 text-xs">
                        Efi Pylarinou
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div
                className="flex-col self-center mt-48 w-1/12 pl-12 pr-12 w-2/12"
                style={{ minWidth: 500 }}
              >
                <div className="justify-center">
                  <h3 className="mt-10 font-body uppercase font-semibold tracking-wider">
                    Partners / Associations
                  </h3>
                  <p className="mt-6 font-body font-light opacity-70 md:w-4/5 text-lg">
                    We have partnered with best-in-class service providers to
                    ensure the highest standards are maintained in everything we
                    do.
                  </p>
                  <p className="mt-8 font-body font-light opacity-70 md:w-4/5 text-lg">
                    First Blockchain Real Estate Company which became Member of
                    EPRA (European Public Real Estate Association). Also Member
                    in CVA (Crypto Valley Association) in Switzerland.
                  </p>
                </div>
              </div>
              <div
                className="flex-col self-center w-1/12 pr-24"
                style={{ minWidth: 300 }}
              >
                <div className="justify-center">
                  <div>
                    <a href="https://cryptovalley.swiss" target="_blank">
                      <img src={cryptoValley} />
                    </a>
                  </div>

                  <div>
                    <a href="https://blockchain.swisscom.com" target="_blank">
                      <img src={swisscom} />
                    </a>
                  </div>
                  <div>
                    <a href="https://www.epra.com" target="_blank">
                      <img src={erpa} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default Team
