import React, { PureComponent } from "react"

export default function withRedirect(Component) {
  return class extends PureComponent {    

    componentDidMount() {   
      const redirectURL = this.extractRedirectUrl()
      if (redirectURL !== null) {
        window.dataLayer.push({
          'event': 'gtm_redirect_url',
          'gtm_redirect_url': redirectURL
        });
      }
    }    

    extractRedirectUrl = () => {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get('redirect');
    }

    render() {      
      return (
        <Component
          {...this.props}          
        />
      )
    }
  }
}
